'use strict';

var cart = require('app_conairllc_sfra_browse/cart/cart');

var updateMiniCart = true;

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart-link').hover(function () {
        $('#minicart-panel-right').modal('show');
    }, function () {
        $('#minicart-panel-right').modal('hide');
    });

    $('.disable-hover-event').off('mouseenter mouseleave');

    $('#minicart-panel-right').on('show.bs.modal', function () {
        var url = $('.minicart').data('action-url');
        var $modal = $(this);
        var $modalContent = $modal.find('.modal-content');
        $modalContent.spinner().start();
        if (url) {
            $.get(url, function (data) {
                $modalContent.empty().append(data);
                if ($modal.find('.modal-content .minicart-footer').length) {
                    $modalContent.addClass('has-sticky-footer');
                }
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });

    $('.modal').on('click', '.custom-backdrop', function (e) {
        if (e.target === e.currentTarget) {
            $(this).closest('.modal').modal('hide');
        }
    });

    $('#removeProductModal').on('hidden.bs.modal', function () {
        // to avoid double scrollbar appearances
        if ($('#minicart-panel-right').hasClass('show')) {
            $('body').addClass('modal-open').css('padding-right', '17px');
        }
    });

    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};
