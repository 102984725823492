'use strict';

var formValidation = require('base/components/formValidation');

$('form.ic-form, form.oi-form, form.pi-form, form.sr-form, form.mi-form').on('submit', function (e) {
    var form = $(this);
    e.preventDefault();
    var url = form.attr('action');
    $.spinner().start();
    var formData = new FormData($(this)[0]);
    var $mainSection = form.closest('.main-section');
    var $response = $mainSection.siblings('.response');
    $response.text('');
    $response.addClass('d-none');
    $response.removeClass('d-block is-invalid invalid-feedback body-m-bold color--error mx-auto');
    $.ajax({
        url: url,
        type: 'POST',
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
            $.spinner().stop();
            if (data.htmlResponseData) {
                $response.append(data.htmlResponseData);
                $response.removeClass('d-none');
                $mainSection.addClass('d-none');
                $('html, body').animate({ scrollTop: 0 }, 300);
            } else if (data.error) {
                $mainSection.find('.file-upload .invalid-feedback').css('display', 'block');
                $mainSection.find('.custom-file-upload').addClass('is-invalid');
                $mainSection.find('.file-upload .upload-btn-wrapper').addClass('bg--error-light');
                $mainSection.find('.file-upload .invalid-feedback').html('').append(data.message);
            } else if (data.fields) {
                formValidation(form, data);
            }
        },
        error: function (data) {
            $.spinner().stop();
            if (!data || !data.responseJSON) {
                $response.text("For technical reasons, your request could not be handled properly at this time. We apologize for any inconvenience. Please try again");
                $response.removeClass('d-none');
                $response.addClass('d-block is-invalid invalid-feedback body-m-bold color--error mx-auto');
                return false;
            }
            if (data.responseJSON.redirectUrl) {
                window.location.href = data.responseJSON.redirectUrl;
            }
            if (data.responseJSON.message) {
                $response.text(data.responseJSON.message);
                $response.removeClass('d-none');
                $response.addClass('d-block is-invalid invalid-feedback body-m-bold color--error mx-auto');
            }
            if (data.responseJSON.fields) {
                if (data.responseJSON.error) {
                    $.each(data.responseJSON.fields, function (fieldName, errorMessage) {
                        var fieldWithError = $('[name="' + fieldName + '"]');
                        if (fieldWithError.length) {
                            fieldWithError.addClass('is-invalid bg--error-light');
                            fieldWithError.siblings('.invalid-feedback').text(errorMessage);
                        }
                    });
                    if (form.hasClass('pi-form')) {
                        try {
                            var $firstInvalidField = form.find('.is-invalid').first();
                            if ($firstInvalidField.length) {
                                $('html, body').animate({
                                    scrollTop: $firstInvalidField.offset().top - 150 // Adjust offset as needed
                                }, 2000);
                                $firstInvalidField.focus();
                            }
                        } catch (error) {
                            console.error('An error occurred while scrolling to the invalid field:', error);
                        }
                    }
                }
            }
        }
    });
    return false;
});
