'use strict';

var base = require('base/components/clientSideValidation');


/**
 * Validate that either Receipt Upload or Date Code/Serial Number is provided
 * @returns {boolean} - Flag to indicate if either field is filled
 */
function validateUploadOrSerial() {
    var $upload = $('#attachment');
    var $serialordate = $('#serialordate');
    var valid = true;

    if (!$upload.val() && !$serialordate.val()) {
        valid = false;
        $upload.addClass('is-invalid bg--error-light');
        $serialordate.addClass('is-invalid bg--error-light');

        $upload.parents('.form-group').find('.invalid-feedback').html('Please upload a receipt or enter a serial number/date code.');
        $serialordate.parents('.form-group').find('.invalid-feedback').html('Please upload a receipt or enter a serial number/date code.');
    } else {
        $upload.removeClass('is-invalid bg--error-light');
        $serialordate.removeClass('is-invalid bg--error-light');
        $upload.parents('.form-group').find('.invalid-feedback').empty();
        $serialordate.parents('.form-group').find('.invalid-feedback').empty();
    }

    return valid;
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

/**
 * Scrolls to the first invalid input field.
 */
function scrollToFirstError() {
    const firstError = $('form input.is-invalid, form select.is-invalid, form textarea.is-invalid').first();
    if (firstError.length) {
        $('html, body').animate({
            scrollTop: firstError.offset().top - 150
        }, 2000);
        firstError.focus();
    }
}
$('#piSubmit').on('click', function (e) {
    var form = $(this).closest('form')[0];

    var isFormValid = validateForm.call(form, e);
    var isUploadOrSerialValid = validateUploadOrSerial();

    if (!isFormValid || !isUploadOrSerialValid) {
        e.preventDefault();
        scrollToFirstError();
    }
});

/**
 * validate confirmation form field.
 * @param {string} input - accepts valid DOM selector for input field.
 * @param {string} confirmationInput - accepts valid DOM selector for confirmationInput field.
 */
function validateConfirmationField(input, confirmationInput) {
    // to execute default validation
    if (!input || !confirmationInput || $(input).val() === '' || $(confirmationInput).val() === '') {
        return;
    }

    var validationMessage = $(confirmationInput).data('confirm-error');
    if ($(input).val() !== $(confirmationInput).val()) {
        $(confirmationInput).addClass('is-invalid bg--error-light');
        $(confirmationInput).parents('.form-group').find('.invalid-feedback')
            .html(validationMessage);
    } else {
        $(confirmationInput).removeClass('is-invalid bg--error-light');
        $(confirmationInput).parents('.form-group').find('.invalid-feedback').empty();
    }
}

/**
 * Function to autocorrect the USA zip code
 * @param {string} zipCodeInput - accepts valid DOM selector for input field.
 * @param {string} pattern - accepts valid regex.
 * @param {number} minLength - accepts integer value.
 * @param {number} maxLength - accepts integer value.
 */
function usaZipCode(zipCodeInput, pattern, minLength, maxLength) {
    var $zipCode = $(zipCodeInput);
    var zipCodeValue = $zipCode.length && $zipCode.val().trim();
    var regex = new RegExp(pattern);
    var match = regex.exec(zipCodeValue);
    var correctedZipCode = '';
    if (!match && zipCodeValue.length >= minLength && zipCodeValue.length === maxLength - 1 && zipCodeValue.indexOf('-') === -1) {
        correctedZipCode = zipCodeValue.slice(0, minLength) + (zipCodeValue.length > minLength ? '-' + zipCodeValue.slice(minLength, maxLength - 1) : '');
        $zipCode.val(correctedZipCode);
    }
}

/**
 * Function to validate and autocorrect the zip code
 * @param {string} zipCodeInput - accepts valid DOM selector for input field.
 * @param {string} country - accepts country name e.g.'US'
 */
function autoCorrectZipCode(zipCodeInput, country) {
    var pattern = $(zipCodeInput).attr('pattern');
    var minLength = $(zipCodeInput).attr('minlength');
    var maxLength = $(zipCodeInput).attr('maxlength');

    switch (country) {
        case 'US':
            usaZipCode(zipCodeInput, pattern, minLength, maxLength);
            break;
        default:
            break;
    }
}

base.invalid = function () {
    $('form input, form select, form textarea').on('invalid', function (e) {
        e.preventDefault();
        this.setCustomValidity('');
        if (!this.validity.valid) {
            var validationMessage = this.validationMessage;
            $(this).addClass('is-invalid bg--error-light');
            if ($(e.target).attr('id') === 'state' || 'country') {
                $(this).closest('.ca-dropdown').find('.form-control').addClass('is-invalid bg--error-light');
            }
            if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                validationMessage = $(this).data('pattern-mismatch');
            }
            if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                && $(this).data('range-error')) {
                validationMessage = $(this).data('range-error');
            }
            if ((this.validity.tooLong || this.validity.tooShort)
                && $(this).data('range-error')) {
                validationMessage = $(this).data('range-error');
            }
            if (this.validity.valueMissing && $(this).data('missing-error')) {
                validationMessage = $(this).data('missing-error');
            }
            $(this).parents('.form-group').find('.invalid-feedback')
                .html(validationMessage);
        }
    });
};

base.onBlur = function () {
    $('form input, form input[type="text"], form input[type="email"], form select, form textarea').on('blur', function (e) {
        $(this).removeClass('is-invalid bg--error-light');
        if ($(this).attr('type') === 'email') {
            validateConfirmationField('input.email', 'input.confirm-email');
        } else if ($(this).attr('type') === 'password') {
            validateConfirmationField('input.password', 'input.confirm-password');
        } else if ($(this).hasClass('js-zipcode')) {
            autoCorrectZipCode($(this), $(this).data('country'));
        }
        if ($(this).is('#attachment') || $(this).is('#serialordate')) {
            validateUploadOrSerial();
        }
        return validateForm.call(this, e);
    });
};

$('form').off('click').on('click', '.ca-dropdown .dropdown-item', function (e) {
    $(this).closest('.ca-dropdown').find('button, .selected-text-hidden').removeClass('is-invalid bg--error-light');

    return validateForm.call($(this).closest('.ca-dropdown').find('.selected-text-hidden'), '');
});

base.print = function () {
    $('form').on('click', '.btn-print', function (e) {
        e.preventDefault();
        var $form = $(this).closest('form');

        if ($form[0].checkValidity()) {
            window.print();
        }
    });
};

module.exports = base;
