'use strict';

// Import individual SVG files - TODO - Use importAll to import all images - Ganesh K
import account from '../../images/icons/account.svg';
import arrowNavigationDown from '../../images/icons/arrowNavigationDown.svg';
import arrowNavigationDownGrey4 from '../../images/icons/arrowNavigationDownGrey4.svg';
import arrowNavigationLeft from '../../images/icons/arrowNavigationLeft.svg';
import arrowNavigationRight from '../../images/icons/arrowNavigationRight.svg';
import arrowNavigationUp from '../../images/icons/arrowNavigationUp.svg';
import bag from '../../images/icons/bag.svg';
import benefits from '../../images/icons/benefits.svg';
import calendar from '../../images/icons/calendar.svg';
import recipe from '../../images/icons/recipe.svg';
import clock from '../../images/icons/clock.svg';
import controlCheck from '../../images/icons/controlCheck.svg';
import controlDismiss from '../../images/icons/controlDismiss.svg';
import controlMinusCircle from '../../images/icons/controlMinusCircle.svg';
import controlMinus from '../../images/icons/controlMinus.svg';
import controlMinusWhite from '../../images/icons/controlMinusWhite.svg';
import controlPause from '../../images/icons/controlPause.svg';
import controlPlay from '../../images/icons/controlPlay.svg';
import controlPlusCircle from '../../images/icons/controlPlusCircle.svg';
import controlPlus from '../../images/icons/controlPlus.svg';
import controlPlusWhite from '../../images/icons/controlPlusWhite.svg';
import creditCard from '../../images/icons/creditCard.svg';
import delivery from '../../images/icons/delivery.svg';
import discontinued from '../../images/icons/discontinued.svg';
import dollarSign from '../../images/icons/dollarSign.svg';
import email from '../../images/icons/email.svg';
import equal from '../../images/icons/equal.svg';
import externalLink from '../../images/icons/externalLink.svg';
import filter from '../../images/icons/filter.svg';
import filledBag from '../../images/icons/FilledBag.svg';
import fruitBasket from '../../images/icons/fruitBasket.svg';
import globe from '../../images/icons/globe.svg';
import part from '../../images/icons/part.svg';
import heartInvert from '../../images/icons/heartInvert.svg';
import heart from '../../images/icons/heart.svg';
import manual from '../../images/icons/manual.svg';
import menu from '../../images/icons/menu.svg';
import moon from '../../images/icons/moon.svg';
import notificationCheckCircle from '../../images/icons/notificationCheckCircle.svg';
import notificationCheckInvert from '../../images/icons/notificationCheckInvert.svg';
import notificationErrorCircle from '../../images/icons/notificationErrorCircle.svg';
import notificationErrorInvert from '../../images/icons/notificationErrorInvert.svg';
import notificationInfoCircle from '../../images/icons/notificationInfoCircle.svg';
import notificationInfoInvert from '../../images/icons/notificationInfoInvert.svg';
import numbersInvert from '../../images/icons/numbersInvert.svg';
import numbers from '../../images/icons/numbers.svg';
import pdf from '../../images/icons/pdf.svg';
import phone from '../../images/icons/phone.svg';
import printer from '../../images/icons/printer.svg';
import productAssistance from '../../images/icons/productAssistance.svg';
import product from '../../images/icons/product.svg';
import receipt from '../../images/icons/receipt.svg';
import refineSearch from '../../images/icons/refineSearch.svg';
import registerProduct from '../../images/icons/registerProduct.svg';
import returnIcon from '../../images/icons/returnIcon.svg';
import save from '../../images/icons/save.svg';
import serving from '../../images/icons/serving.svg';
import share from '../../images/icons/share.svg';
import socialMediaFacebook from '../../images/icons/socialMediaFacebook.svg';
import socialMediaGoogle from '../../images/icons/socialMediaGoogle.svg';
import socialMediaGoogleColor from '../../images/icons/socialMediaGoogleColor.svg';
import socialMediaInstagram from '../../images/icons/socialMediaInstagram.svg';
import socialMediaLinkedIn from '../../images/icons/socialMediaLinkedIn.svg';
import socialMediaPinterest from '../../images/icons/socialMediaPinterest.svg';
import socialMediaTiktok from '../../images/icons/socialMediaTiktok.svg';
import socialMediaYoutube from '../../images/icons/socialMediaYoutube.svg';
import sort from '../../images/icons/sort.svg';
import starHalf from '../../images/icons/starHalf.svg';
import starWhole from '../../images/icons/starWhole.svg';
import star from '../../images/icons/star.svg';
import sun from '../../images/icons/sun.svg';
import time from '../../images/icons/time.svg';
import users from '../../images/icons/users.svg';
import socialMediaTwitter from '../../images/icons/socialMediaTwitter.svg';
import whisk from '../../images/icons/whisk.svg';
import zoomIn from '../../images/icons/zoomIn.svg';
import zoomOut from '../../images/icons/zoomOut.svg';
import applePay from '../../images/icons/applePay.svg';
import paypalPay from '../../images/icons/paypalPay.svg';
import googlePay from '../../images/icons/googlePay.svg';
import faq from '../../images/icons/faq.svg';
import spares from '../../images/icons/spares.svg';
import trackOrder from '../../images/icons/trackOrder.svg';



// Function to insert SVG into HTML
const insertSVG = (svgString, targetClass) => {
    const targetElements = document.querySelectorAll(`.${targetClass}`);
    if (targetElements) {
        targetElements.forEach((targetElement) => {
            targetElement.innerHTML = svgString;
        });
    }
};

//TODO - Use array and loop - Ganesh K
insertSVG(account, 'icon-account');
insertSVG(arrowNavigationDown, 'icon-arrowNavigationDown');
insertSVG(arrowNavigationDownGrey4, 'icon-arrowNavigationDownGrey4');
insertSVG(arrowNavigationLeft, 'icon-arrowNavigationLeft');
insertSVG(arrowNavigationRight, 'icon-arrowNavigationRight');
insertSVG(arrowNavigationUp, 'icon-arrowNavigationUp');
insertSVG(bag, 'icon-bag');
insertSVG(benefits, 'icon-benefits');
insertSVG(calendar, 'icon-calendar');
insertSVG(recipe, 'icon-recipe');
insertSVG(clock, 'icon-clock');
insertSVG(controlCheck, 'icon-controlCheck');
insertSVG(controlDismiss, 'icon-controlDismiss');
insertSVG(controlMinus, 'icon-controlMinus');
insertSVG(controlMinusCircle, 'icon-controlMinusCircle');
insertSVG(controlPause, 'icon-controlPause');
insertSVG(controlPlay, 'icon-controlPlay');
insertSVG(controlPlus, 'icon-controlPlus');
insertSVG(controlPlusCircle, 'icon-controlPlusCircle');
insertSVG(creditCard, 'icon-creditCard');
insertSVG(delivery, 'icon-delivery');
insertSVG(dollarSign, 'icon-dollarSign');
insertSVG(discontinued, 'icon-discontinued');
insertSVG(email, 'icon-email');
insertSVG(equal, 'icon-equal');
insertSVG(externalLink, 'icon-externalLink');
insertSVG(filter, 'icon-filter');
insertSVG(filledBag, 'icon-filledBag');
insertSVG(fruitBasket, 'icon-fruitBasket');
insertSVG(globe, 'icon-globe');
insertSVG(part, 'icon-part');
insertSVG(heart, 'icon-heart');
insertSVG(heartInvert, 'icon-heartInvert');
insertSVG(manual, 'icon-manual');
insertSVG(menu, 'icon-menu');
insertSVG(moon, 'icon-moon');
insertSVG(notificationCheckCircle, 'icon-notificationCheckCircle');
insertSVG(notificationCheckInvert, 'icon-notificationCheckInvert');
insertSVG(notificationErrorCircle, 'icon-notificationErrorCircle');
insertSVG(notificationErrorInvert, 'icon-notificationErrorInvert');
insertSVG(notificationInfoCircle, 'icon-notificationInfoCircle');
insertSVG(notificationInfoInvert, 'icon-notificationInfoInvert');
insertSVG(numbers, 'icon-numbers');
insertSVG(numbersInvert, 'icon-numbersInvert');
insertSVG(phone, 'icon-pdf');
insertSVG(phone, 'icon-phone');
insertSVG(printer, 'icon-printer');
insertSVG(productAssistance, 'icon-productAssistance');
insertSVG(product, 'icon-product');
insertSVG(receipt, 'icon-receipt');
insertSVG(refineSearch, 'icon-refineSearch');
insertSVG(registerProduct, 'icon-registerProduct');
insertSVG(returnIcon, 'icon-returnIcon');
insertSVG(save, 'icon-save');
insertSVG(serving, 'icon-serving');
insertSVG(share, 'icon-share');
insertSVG(socialMediaFacebook, 'icon-socialMediaFacebook');
insertSVG(socialMediaGoogle, 'icon-socialMediaGoogle');
insertSVG(socialMediaGoogleColor, 'icon-socialMediaGoogleColor');
insertSVG(socialMediaInstagram, 'icon-socialMediaInstagram');
insertSVG(socialMediaLinkedIn, 'icon-socialMediaLinkedIn');
insertSVG(socialMediaPinterest, 'icon-socialMediaPinterest');
insertSVG(socialMediaTiktok, 'icon-socialMediaTiktok');
insertSVG(socialMediaYoutube, 'icon-socialMediaYoutube');
insertSVG(socialMediaTwitter, 'icon-socialMediaTwitter');
insertSVG(sort, 'icon-sort');
insertSVG(star, 'icon-star');
insertSVG(starHalf, 'icon-starHalf');
insertSVG(starWhole, 'icon-starWhole');
insertSVG(sun, 'icon-sun');
insertSVG(time, 'icon-time');
insertSVG(users, 'icon-users');
insertSVG(whisk, 'icon-whisk');
insertSVG(zoomIn, 'icon-zoomIn');
insertSVG(zoomOut, 'icon-zoomOut');
insertSVG(applePay, 'icon-applePay');
insertSVG(paypalPay, 'icon-paypalPay');
insertSVG(googlePay, 'icon-googlePay');
insertSVG(faq, 'icon-faq');
insertSVG(spares, 'icon-spares');
insertSVG(trackOrder, 'icon-trackOrder');
