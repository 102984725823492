'use strict';

var swiper = require('swiper/bundle');
var Swiper = swiper.Swiper;

/**
 * Swiper config for Recommendations
 */
function initRecommendationSwiper() {
    var recommendationSwiper = new Swiper('.js-recommendations-swiper', {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 24,
        pagination: {
            el: '.swiper-custom-pagination',
            clickable: true, 
            enabled: true
        },
        navigation: {
            nextEl: '.swiper-next-btn',
            prevEl: '.swiper-prev-btn'
        },
        breakpoints: {
            640: {
                slidesPerView: 1.5,
                spaceBetween: 16
            },
            768: {
                slidesPerView: 3.5,
                spaceBetween: 16
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 16
            }
        }
    });
}

module.exports = {
    initRecommendationSwiper: initRecommendationSwiper
};
