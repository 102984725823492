'use strict';

var $youtubeModal = $('.modal-youtube-pd');

module.exports = {
    initVideoComponentPreviewButton: function () {
        $(function () {
            var player = [];
            var playvideoOnload = {};
            var videoLoaded = {};

            const loadVideo = () => {
                var youtubeIframe = $('.js-video-player-component-iframe');
                youtubeIframe.length && youtubeIframe.each(function (index, frame) {
                    player.push(new window.YT.Player($(frame)[0], {
                        events: {
                            onReady: function (evt) {
                                if (playvideoOnload[index]) {
                                    evt.target.playVideo();
                                }
                                videoLoaded[index] = true;
                                let url = window.location.href;
                                if (url.includes('#recipe-detail-youtube')) {
                                    $('.video-player-component__preview button').trigger('click');
                                }
                            }
                        }
                    }));
                });
            };

            if (typeof window.YT !== 'undefined' && window.YT.loaded) {
                loadVideo();
            }

            window.onYouTubeIframeAPIReady = function () {
                loadVideo();
            };

            $('body').off('shown.bs.modal').on('shown.bs.modal', $youtubeModal, function (e) {
                var index = $youtubeModal.index(e.target);
                if (videoLoaded[index]) {
                    player[index].playVideo();
                } else {
                    playvideoOnload[index] = true;
                }
            });

            $('body').off('hide.bs.modal').on('hide.bs.modal', $youtubeModal, function (e) {
                var index = $youtubeModal.index(e.target);
                if (videoLoaded[index]) {
                    player[index].pauseVideo();
                } else {
                    playvideoOnload[index] = true;
                }
            });
        });
    }
};
