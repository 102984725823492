'use strict';

window.jQuery = require('jquery');
window.$ = require('jquery');

var processInclude = require('base/util');

$(function () {
    // SFRA scripts
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('app_cuisinart_sfra_base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));

    // Custom cuisinart scripts
    processInclude(require('./common'));
    processInclude(require('./components/icons'));
    processInclude(require('./components/forms'));
    processInclude(require('./components/dynamicModal'));
    processInclude(require('plugin_gtm_custom/gtmEvents/gtmEvents'));

    processInclude(require('./components/form'));
    processInclude(require('app_conairllc_sfra_browse/components/clientSideValidation'));

    processInclude(require('app_conairllc_sfra_browse/search/search'));
    processInclude(require('plugin_wishlists_custom/product/wishlistHeart'));
    processInclude(require('app_conairllc_sfra_browse/product/addToCartPLP'));
    processInclude(require('app_conairllc_sfra_browse/product/quickView'));
    processInclude(require('app_cuisinart_sfra_base/components/miniCart'));
    processInclude(require('app_conairllc_sfra_account/login/login'));
    processInclude(require('./login/recaptcha'));
});

require('./thirdParty/bootstrap');
require('base/components/spinner');
